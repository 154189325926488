export const INSURANCE_COMPANIES = [
    {
        label: "Velg forsikringsselskap",
    },
    {
        label: "Help Forsikring",
        id: "Help",
    },
    {
        label: "Gjensidige",
        id: "Gjensidige",
    },
    {
        label: "KLP",
        id: "KLP",
    },
    // {
    //     label: "If",
    //     id: "If",
    // },
    {
        label: "Storebrand",
        id: "Storebrand",
    },
    {
        label: "Tryg Forsikring",
        id: "Tryg",
    },
    {
        label: "Frende",
        id: "Frende",
    },
    {
        label: "Landkreditt",
        id: "Landkreditt",
    },
    {
        label: "Kravia",
        id: "Kravia",
    },
    {
        label: "Fremtind",
        id: "Fremtind",
    },
    {
        label: "Watercircles",
        id: "Watercircles",
    },
    {
        label: "Ikke på listen",
        id: "",
    },
];
